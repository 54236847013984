@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");

@import "bootstrap/dist/css/bootstrap.css";
@import "./theme/colors.css";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root,
.App {
  min-width: 100%;
  min-height: 100%;
  background: #f3f3f3 center/cover no-repeat fixed;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
label,
p,
textarea,
input,
div,
button {
  font-family: "Inter", sans-serif;
  margin: 0;
  padding: 0;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

input {
  min-width: 0;
}

.css-1jqq78o-placeholder {
  font-size: 13.5px;
}

/* .accordion-body {
  padding: 0px !important;
} */
