.container {
  width: 100%;
  display: flex;
  height: 100vh;
  background-color: var(--background-color);
}

.sidebar-container {
  height: 100%;
}

.sidebar-container_mobile {
  display: none;
}

.content-container {
  flex: 1;
  display: flex;
  flex-direction: column;

  .pages-wrapper {
    flex: 1 0;
    overflow-y: auto;
    padding: 0 15px;
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
@media only screen and (max-width: 768px) {
  .sidebar-container {
    display: none;
  }
  .sidebar-container_mobile {
    display: block;
    height: 100%;
    position: fixed;
    top: 0;
    transition: .5s;
    z-index: 999;
  }
  .content-container {
    overflow: auto;

    .pages-wrapper {
      padding: 15px;
    }
  }
}
