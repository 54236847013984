.container {
  position: absolute;
  width: 80%;
  height: 78%;
  background: rgba(0, 0, 0, 0.49);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px 5px 0 0;
  .modal {
    border-radius: 10px;
    background: #ffffff;
    z-index: 999999;
  }
}
